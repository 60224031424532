import rest from './rest';

export default class auth {
    static createToken = (applicationId, email, successCallback, errorCallback) => {
        var credentials = {
            applicationId: applicationId,
            email: email
        };

        rest.post('auth', 'createToken', credentials, successCallback, errorCallback);
    }

    static getToken = (qs, successCallback, errorCallback) => {
        rest.get('auth', 'getToken', qs, successCallback, errorCallback);
    }

    static requestToken = (applicationId, pin, successCallback, errorCallback) => {
        var credentials = {
            applicationId: applicationId,
            pin: pin
        };

        rest.post('auth', 'requestToken', credentials, successCallback, errorCallback);
    }   

    static validateToken = (token, successCallback, errorCallback) => {
        rest.getAuth('auth', 'validateToken', successCallback, errorCallback);
    }
} 