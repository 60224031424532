import rest from './rest'

export default class storage {
    static add = (name, entity, successCallback, errorCallback) => {
        rest.postAuth('storage', 'add/' + name, entity, successCallback, errorCallback);
    }

    static get = (name, successCallback, errorCallback) => {
        rest.getAuth('storage', 'get/' + name, successCallback, errorCallback);
    }

    static getById = (name, id, successCallback, errorCallback) => {
        rest.getAuth('storage', 'get/' + name + '/' + id, successCallback, errorCallback);
    }   

    static modify = (name, entity, successCallback, errorCallback) => {
        rest.putAuth('storage', 'modify/' + name, entity, successCallback, errorCallback);
    }

    static remove = (name, id, successCallback, errorCallback) => {
        rest.postAuth('storage', 'remove/' + name + '/' + id, null, successCallback, errorCallback);
    }

    static truncate = (name, successCallback, errorCallback) => {
        rest.postAuth('storage', 'truncate/' + name, null, successCallback, errorCallback);
    }
}  