export var RegistrationViewModel = {
    title: 'Registrations',
    service: 'Registration',
    addButtonText: 'Add Registration',
    list: {
        items: [{
            label: 'Firstname',
            field: 'Firstname'
        }, {
            label: 'Surname',
            field: 'Surname'
        }, {
            label: 'Email Alert',
            field: 'EmailAlert',
            type: 'checkbox'
        },{
            label: 'SMS Alert',
            field: 'SMSAlert',
            type: 'checkbox'
        }],
        deleteModalMessage: "Are you sure you want to delete this registration?",
        data: []
    },
    inputForm: {
        items: [{
            label: 'First Name',
            field: 'Firstname',
            rules: [{ required: true, message: 'Please enter a first name' }]
        }, {
            label: 'Surname',
            field: 'Surname',
            rules: [{ required: true, message: 'Please enter a surname' }]
        }, {
            label: 'Date of Birth',
            field: 'DOB',
            type: 'date',
            rules: [{ required: true, message: 'Please enter a date of birth' }]
        }, {
            label: 'Email',
            field: 'Email',
            rules: [{ required: true, message: 'Please enter an email address' }]
        }, {
            label: 'Email Alert',
            field: 'EmailAlert',
            type: 'checkbox',
            rules: [{ required: false }]
        },  {
            label: 'SMS Alert',
            field: 'SMSAlert',
            type: 'checkbox',
            rules: [{ required: false }]
        }]
    }
}