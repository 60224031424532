export var TrayPatientViewModel = {
    title: 'Tray Patients',
    service: 'TrayPatient',
    addButtonText: 'Add Tray Patient',
    list: {
        items: [{
            label: 'First Name',
            field: 'Firstname'
        }, {
            label: 'Surname',
            field: 'Surname'
        }, {
            label: 'Date of Birth',
            field: 'DOB',
            type: 'date'
        }, {
            label: 'Start Date',
            field: 'StartDate',
            type: 'date',
        }, {
            label: 'Delivery',
            field: 'Delivery',
            type: 'checkbox'
        }, {
            label: 'Frequency',
            field: 'Frequency'
        }],
        deleteModalMessage: "Are you sure delete this tray patient?",
        data: []
    },
    inputForm: {
        items: [{
            label: 'First Name',
            field: 'Firstname',
            rules: [{ required: true, message: 'Please enter your first name' }]
        }, {
            label: 'Surname',
            field: 'Surname',
            rules: [{ required: true, message: 'Please enter your surname' }]
        }, {
            label: 'Date of Birth',
            field: 'DOB',
            type: 'date',
            rules: [{ required: true, message: 'Please enter your date of birth' }]
        }, {
            label: 'Address',
            field: 'Address',
            type: 'textArea',
            rules: [{ required: true, message: 'Please enter your address' }]
        }, {
            label: 'Post Code',
            field: 'PostCode',
            rules: [{ required: true, message: 'Please enter your post code' }]
        }, {
            label: 'Frequency',
            field: 'Frequency',
            type: 'select',
            values: ['Weekly', 'Monthly'],
            defaultValue: 'Weekly',
            rules: [{ required: true, message: 'Please enter the prescription frequency' }]
        }, {
            label: 'Start Date',
            field: 'StartDate',
            type: 'date',
            rules: [{ required: true, message: 'Please enter a starting date' }]
        }, {
            label: 'Collection Site',
            field: 'CollectionSite',
            type: 'select',
            values: ['N/A', 'Excalibur Drive', 'Lisvane', '67 Thornhill Road'],
            defaultValue: 'N/A',
            rules: [{ required: true, message: 'Please enter a collection site' }]
        }, {
            label: 'Delivery',
            field: 'Delivery',
            type: 'checkbox',
            rules: [{ required: false }]
        }]
    }
}