let env;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'live-mojo-portal.web.app' || hostname === 'mojo.vudu.tech') {
  env = 'live-';
} else if(hostname === 'dev-mojo.azurewebsites.net' || hostname == 'localhost') {
  env = 'dev-';
} else if(hostname === 'demo-mojo.azurewebsites.net')  {
  env = 'demo-';
} else {
  env = 'invalid-domain-';
}

export const ENV = `${env}`;