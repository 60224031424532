import * as React from 'react'
import web from '../web'
import { Modal, Button, Input, message } from 'antd';

export default class AuthContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            applicationId: this.props.applicationId,
            email: '',
            loading: true, 
            authenticated: false,
            submitted: false
        }
    }

    componentWillMount = () => {
        message.loading('Please wait...', 0.5)

        var that = this;
        web.hasValidAccessToken(auth => {     
            that.setState({ loading: false, authenticated: auth });
        });
    }

    onChangeEmail = (e) => {
        this.setState({ email: e.target.value})
    } 

    submit = () => {
        //message.success('Thanks! Please check your email for an authentication link.', 0);
        this.setState({ submitted: true, loading: true });
        var that = this;
        //web.createToken(this.state.applicationId, this.state.email);
        web.requestToken(this.state.applicationId, this.state.email, (success) => {
            that.setState({ loading: false, authenticated: true });
        });
    }

    render() {
        const { loading, authenticated, submitted } = this.state;

        return (
            <div id="auth-container">
                { loading || (!authenticated && !submitted && 
                <Modal
                    visible={true}
                    title="Request access"
                    onOk={this.submit}
                    footer={[
                        <Button key="submit" type="primary" loading={submitted} onClick={this.submit}>Request Access</Button>,
                    ]}
                >
                    <Input addonBefore="Pin" 
                           value={this.state.email} 
                           onChange={this.onChangeEmail}
                           onPressEnter={this.submit} />
                </Modal>) ||
                authenticated && this.props.children }
            </div>
        );
    }
}