import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CRUDView } from './client-web-shared/admin/CRUDView';
import AuthContainer from './client-web-shared/auth/AuthContainer'
import AdminContainer from './client-web-shared/admin/AdminContainer'
import { AuthView } from './client-web-shared/auth/AuthView'
import { TrayPatientViewModel } from './viewmodels/TrayPatientViewModel'
import { RegistrationViewModel } from  './viewmodels/RegistrationViewModel';
import { UserViewModel } from './viewmodels/UserViewModel'

export class ApplicationView extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/auth" render={(routeProps) => <AuthView rp={routeProps} />} />
                    <AuthContainer applicationId="d6a0b5f4-6028-4e32-89c0-ea2f06dc0a73">
                        <AdminContainer>
                            <Route path="/TrayPatient" render={() => <CRUDView vm={TrayPatientViewModel} name='TrayPatient'/>} />
                            <Route path="/Registration" render={() => <CRUDView vm={RegistrationViewModel} name='Registration'/>} />
                            <Route path="/User" render={() => <CRUDView vm={UserViewModel} name='User'/>} />                         
                        </AdminContainer>
                    </AuthContainer>
                </Switch>
            </Router>
        );
    }
}