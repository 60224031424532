import * as React from 'react';
import web from '../web';

export default class AuthController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: props
        }
    }
 
    signIn(tokenId) {
        var that = this;

        web.signIn(tokenId, auth => {
            that.state.authenticated = auth;
            that.state.view.setState({ authenticated: auth });
        });
    }

    isAuthenticated() {
        return this.state.view.authenticated;
    }
}