import * as React from 'react';
import storage from '../../client-api-shared/api_storage';

export class CRUDController extends React.Component {
    constructor(view) {
        super(view);

        this.state = {
            view: view
        }
    }

    _add = () => { 
        let that = this;

        let payload = this.state.view.state.item;
        delete payload.Id;

        storage.add(this.state.view.state.name, payload, () => {
            that.populateData();
        }, (error) => { 
            console.log(error);
        });
    }

    _edit = () => {
        let that = this;
        storage.modify(this.state.view.state.name, this.state.view.state.item, () => {
            that.populateData();
        }, (error) => {
            console.log(error);
        });
    }

    _delete = (id) => {
        let that = this;
        storage.remove(this.state.view.state.name, id, () => {
            that.populateData();
        }, (error) => {
            console.log(error);
        });
    }

    initialValue = () => {
        let mode = this.getFormMode();

        if(mode != 'add') {
            return this.state.view.state.item;
        }

        return null;
    }

    populateData() {
        if(!this.isListLoading()) { this.toggleListLoading(); }

        let that = this;
        let data = [];

        that.state.view.setState({ data: [] });

        storage.get(this.state.view.state.name, (response) => {
            
            if(response.data != null) {
                response.data.map((item) => {
                    data.push(item);
                });

                that.state.view.setState({ data: data });
            }
            that.toggleListLoading();
        }, (error) => {
            console.log(error);
        });
    }

    getData = () => {
        return this.state.view.state.data;
    }

    select = (item) => {
        this.state.view.setState({ item: item });
        this.setFormModeSelect();
        this.setComponentForm();
    }

    add = () => {
        this.setFormModeAdd();
        this.setComponentForm();
    }

    edit = (item) => {
        this.state.view.setState({ item: item });
        this.setFormModeEdit();
        this.setComponentForm();
    }

    delete = (item) => {
        this._delete(item.Id);
    }

    formAdd = (item) => {
        this.state.view.setState({ item: item }, () => { this._add() });
        this.setComponentTable();
    }

    formEdit = (item) => {
        let id = this.state.view.state.item.Id;
        item.Id = id;
        this.state.view.setState({ item: item }, () => { this._edit() });
        this.setComponentTable();
    }

    setComponentTable = () => {
        this.state.view.setState({ component: 'table' });
    }

    setComponentForm = () => {
        this.state.view.setState({ component: 'form' });
    }

    getComponent = () => {
        return this.state.view.state.component;
    }

    toggleListLoading = () => {
        this.state.view.setState({ listLoading: !this.state.view.state.listLoading });
    }

    isListLoading = () => {
        return this.state.view.state.listLoading;
    }

    setFormModeAdd() {
        this.state.view.setState({ formMode: 'add' });
    }

    setFormModeSelect() {
        this.state.view.setState({ formMode: 'select' });
    }

    setFormModeEdit() {
        this.state.view.setState({ formMode: 'edit' });
    }

    getFormMode() {
        return this.state.view.state.formMode;
    }
}