import * as React from 'react'
import { Button, Icon, Menu, Avatar, Input } from 'antd';
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
import logo from './rococo-icon.png';
import { Link } from 'react-router-dom';

const style = {
    background: {
        margin: 0,
        width: '100%',
        position: 'absolute',
        top: '-65px',
        zIndex: '-1'
    },
    logo: {
        display: 'inline-block',
        backgroundColor: '#ee8419', 
        marginLeft: '15px',
        padding: '10px',
        borderRadius: '10px',
        marginBottom: '-5px',
        width: '33px',
        verticalAlign: 'text-bottom'
    },
    siteHeader: {
        borderBottom: '1px solid #e8e8e8',
        color: '#FFF'
    },
    userAccount: {
        position: 'absolute',
        right: '1%',
        top: '5px'
    },
    userAccountText: {
        marginLeft: '10px',
        fontSize: '1.3rem',
        marginTop: '5px'
    },
    avatar: {
        position: 'absolute',
        top: '-5px',
        left: '-40px'
    },
    menu: {
        float: 'left',
        width: '10%'
    },
    header: {
        fontSize: '1.4rem',
        lineHeight: '2.5rem',
        display: 'inline-block',
        marginLeft: '10px'
    },
}

export default class AdminContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="admin-container">
                <div style={style.siteHeader}>
                    <span><img src={logo} style={style.logo} alt="logo" /></span>
                    <span style={style.header}>rococo.cloud</span>
                    <span style={style.userAccount}>
                        {/* <Avatar style={style.avatar} size="large" icon="user" />
                        <span style={style.userAccountText}>gavin david</span> */}
                    </span>
                </div>
                <div id="content-container">
                    <div style={style.menu}>
                        <Menu
                            defaultOpenKeys={['sub1', 'sub2']}
                            theme='dark'
                            mode="inline"
                        >
                            <SubMenu key="sub1" title={<span><Icon type="database" /><span>Content</span></span>}>
                                <Menu.Item key="1"><Link to="/TrayPatient">Tray Patients</Link></Menu.Item>
                                <Menu.Item key="2"><Link to="/Registration">Registrations</Link></Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub2" title={<span><Icon type="setting" /><span>Administration</span></span>}>
                                <Menu.Item key="3"><Link to="/User">Users</Link></Menu.Item>
                            </SubMenu>
                        </Menu>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}