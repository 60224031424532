import auth from '../client-api-shared/api_auth'

export default class web {
    static createToken = (applicationId, email) => {
        auth.createToken(applicationId, email, (success) => {
        }, (error) => {
        });
    }

    static signIn = (tokenId, successCallback) => {
        auth.getToken('tokenId=' + tokenId, (success) => {
            window.localStorage.setItem('access_token', success.data)
            successCallback(true);
        }, (error) => {
            console.log(error);
        });
    }

    static requestToken = (applicationId, email, successCallback) => {
        auth.requestToken(applicationId, email, (success) => {
            window.localStorage.setItem('access_token', success.data.tokenString)
            successCallback(true);
        }, (error) => {
            console.log(error);
        });
    }

    static signOut = () => {
        window.localStorage.removeItem('access_token');
    }

    static hasValidAccessToken = (setupState) => {
        let hasAccessToken = web._getAccessToken() != null;

        if(hasAccessToken) {
            auth.validateToken(web._getAccessToken(), (success) => {
                setupState(true);
            }, (error) => {
                console.log('invalid access token');
                setupState(false);
                web.signOut();
            });
        }
        else {
            setupState(false);
        }
    }

    static _getAccessToken() {
        return window.localStorage.getItem('access_token');
    }
}