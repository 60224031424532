import * as React from 'react'; 
import { Button, Popconfirm, Table, Checkbox } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';

export default class CRUDList extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [ {
            title: '',
            dataIndex: 'actions',
            width: 150,
            render: (text, row, index) => {
                return <ButtonGroup>
                          <Button icon='select' onClick={(e) => this.props.onSelect(row)} />
                          <Button icon='edit' onClick={(e) => this.props.onEdit(row)} />
                          <Popconfirm title={this.props.list.deleteModalMessage} 
                                      onConfirm={(e) => this.props.onDelete(row)} 
                                      okText="Yes" 
                                      cancelText="No">
                                <Button icon='delete' />
                          </Popconfirm>
                      </ButtonGroup>
            }
        }];

        if(this.props.list && this.props.list.items) {
            this.props.list.items.map((item) => {
                if(item.type == null || item.type == 'select') {
                    this.columns.push({
                        title: item.label,
                        dataIndex: item.field
                    });
                }
                else {
                    switch(item.type) {
                        case 'date': {
                            this.columns.push({
                                title: item.label,
                                dataIndex: item.field,
                                render: (text) => <div>{moment.utc(text).format('DD/MM/YYYY')}</div>
                            });
                            break;
                        }
                        case 'checkbox': {
                            this.columns.push({
                                title: item.label,
                                dataIndex: item.field,
                                render: (text) => <Checkbox disabled={true} defaultChecked={text}/>
                            });
                            break;
                        }
                    }
                }
            });
        }
    }

    render() {
        return (
            <Table columns={this.columns} 
                   dataSource={this.props.list.data} 
                   style={{ margin: '10px' }}
                   loading={this.props.loading}
                   rowKey='Id'/>
        )
    }
}