export var UserViewModel = {
    title: 'User',
    service: 'User',
    addButtonText: 'Add User',
    list: {
        items: [{
            label: 'Name',
            field: 'Name'
        }, {
            label: 'Email Address',
            field: 'Email'
        }],
        deleteModalMessage: "Are you sure delete this user?",
        data: []
    },
    inputForm: {
        items: [{
            label: 'Name',
            field: 'Name',
            rules: [{ required: true, message: 'Please enter your name' }]
        }, {
            label: 'Email Address',
            field: 'Email',
            rules: [{ required: true, message: 'Please enter your email address' }]
        }, {
            label: 'PIN (Between 10000 and 99999)',
            field: 'Pin',
            type: 'number',
            min: 10000,
            max: 99999,
            rules: [{ required: true, message: 'Please enter a valid pin' }]
        }]
    }
}