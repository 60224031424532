import * as React from 'react';
import AuthController from './AuthController'
import { Redirect } from 'react-router-dom';
import 'antd/dist/antd.css' 
import '../admin/admin.css'
import qs from 'query-string'

const style = {
    content: {
        width: '60%',
        float: 'right',
        marginRight: '10%',
        background: '#f7f8f9',
        borderRadius: '10px',
        marginTop: '20px'
    },
};

export class AuthView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: false
        };
    }

    componentWillMount() {
        this.controller = new AuthController(this);
    }

    componentDidMount() {
        this.controller.signIn(qs.parse(this.props.rp.location.search).tokenId);
    }

    render() {
        return (<Redirect to='/'/>);
    }
}