import * as React from 'react';
import { CRUDController } from './CRUDController';
import 'antd/dist/antd.css' 
import { Button, Icon } from 'antd';
import './admin.css'
import CRUDList from  './CRUDList';
import { CRUDForm } from './CRUDForm'

const style = {
    content: {
        width: '90%',
        float: 'right', 
        background: '#f7f8f9'
    },
    headerContainer: {
        position: 'relative',
    },
    header: {
        fontSize: '3rem',
        display: 'inline-block',
        marginLeft: '10px'
    },
    addButton: {
        position: 'absolute',
        right: '20px',
        top: '15px'
    }
};

export class CRUDView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            vm: this.props.vm,
            name: this.props.name,
            data: [],
            item: {
                Id: -1,
            },
            error: false,
            component: 'table',
            formMode: 'add',
            listLoading: true,
            addButtonVisible: true
        }
    }

    componentWillMount() {
        this.vm = this.props.vm;
        this.controller = new CRUDController(this);
        this.controller.populateData();
    }

    render() {
        this.vm.list.data = this.controller.getData();

        return (
            <div style={style.content}>
                <div style={style.headerContainer}>
                    <div style={style.header}>{this.vm.title}</div>
                    {this.state.component == 'table' && <Button size='large' 
                            type='primary' 
                            icon='plus' 
                            style={style.addButton}
                            onClick={this.controller.add}>{this.vm.addButtonText}</Button>}
                </div>
                <div>
                    {this.state.component == 'table' && <CRUDList   list={this.vm.list}
                                loading={this.controller.isListLoading()}
                                onSelect={this.controller.select}
                                onEdit={this.controller.edit}
                                onDelete={this.controller.delete}/>}
                    {this.state.component == 'form' && <CRUDForm inputForm={this.vm.inputForm}
                                mode={this.controller.getFormMode()}
                                initialValue={this.controller.initialValue()}
                                onFormAdd={this.controller.formAdd}
                                onFormEdit={this.controller.formEdit}
                                setFormModeEdit={() => { this.controller.setFormModeEdit(); }}
                                setComponentTable={this.controller.setComponentTable}/>}
                </div>
            </div>
        );
    }
}