import * as React from 'react';
import { Button, Form, Input, Select, Checkbox, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import 'moment/locale/en-gb';
import locale from 'antd/lib/date-picker/locale/en_GB';

const { TextArea } = Input;
const FormItem = Form.Item;

class InternalCRUDForm extends React.Component {
    constructor(props) {
        super(props);

        let submitButtonText = this.props.mode == 'select' ? 'Edit' : 'Save';
        let readOnly = (this.props.mode == 'select');

        this.state = {
            mode: this.props.mode,
            readOnly: readOnly,
            submitButtonText: submitButtonText,
            ...this.props.initialValue
        };
    } 

    handleSumbit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                switch(this.state.mode)
                {
                    case 'add':
                    {
                        this.props.onFormAdd(values);
                        break;
                    }
                    case 'edit':
                    {
                        this.props.onFormEdit(values)
                        break;
                    }
                    case 'select':
                    {
                        this.props.setFormModeEdit();
                        this.setState({
                            submitButtonText: 'Save',
                            readOnly: false,
                            mode: 'edit'
                        });
                        break;
                    }
                }
            }
        });
    }

    close = (e) => {
        this.props.setComponentTable();
    }

    getType = (item) => {
        if(item.type == null) {
            return <Input readOnly={this.state.readOnly} />
        }

        switch(item.type) {
            case 'select': {
                return <Select disabled={this.state.readOnly}>
                    {item.values.map((value, index) => (
                        <Select.Option key={index} value={value}>{value}</Select.Option>
                    ))}
                </Select>
            }
            case 'checkbox': {
                return <Checkbox disabled={this.state.readOnly} defaultChecked={this.state[item.field] || item.defaultValue || false}/>
            }
            case 'date': {
                return <DatePicker disabled={this.state.readOnly} format={'DD/MM/YYYY'} locale={locale} allowClear={false}/>
            }
            case 'textArea': {
                return <TextArea rows={5} readOnly={this.state.readOnly} />
            }
            case 'number': {
                return <InputNumber min={item.min || 0} max={item.max || null} fornatter={value => '${value}'} parser={value => value.toString()} disabled={this.state.readOnly} />
            }
        }
    }

    getDefault = (item) => {
        if(item.type == null) {
            return this.state[item.field] || item.defaultValue || '';
        }

        switch(item.type) {
            case 'select': {
                return this.state[item.field] || item.defaultValue || '';
            }
            case 'date': {
                return moment.utc(this.state[item.field]).startOf('day') || moment.utc(item.defaultValue).startOf('day') || moment().utc().startOf('day');
            }
            case 'textArea': {
                return this.state[item.field] || item.defaultValue || '';
            }
            case 'number': {
                return this.state[item.field] || item.defaultValue || '';
            }
        }
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit} style={{ padding: 20 }}>
                {this.props.inputForm.items.map((item, index) => (
                    <FormItem key={index} label={item.label}
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 12 }}>
                        {getFieldDecorator(item.field, {
                            initialValue: this.getDefault(item),
                            rules: item.rules})( this.getType(item) )}
                    </FormItem>
                ))}
                <FormItem
                    wrapperCol={{ span: 12, offset: 5 }}
                >
                    <Button type="primary" 
                            onClick={this.handleSumbit} 
                            htmlType="submit" 
                            style={{ marginRight: 10 }}>{this.state.submitButtonText}</Button>
                    <Button type="primary" 
                            onClick={this.close}>Close</Button>
                </FormItem>
            </Form>
        );
    }
}

export const CRUDForm = Form.create({})(InternalCRUDForm);